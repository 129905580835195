import React from 'react';

const Crab = ({ size, className }) => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 360 360"
      width={size || 30}
      height={size || 30}
      className={`crab ${className || ''}`}
    >
      <path
        d="M340,182.5c0,13.785-11.215,25-25,25h-17.978c-3.467-11.457-9.85-22.081-18.529-31.417
			c8.314-6.404,14.751-15.123,18.334-25.191C336.651,143.064,360,107.785,360,72.499C360,32.5,330,22.5,330,22.5c0,30-20,50-20,50
			v-40c-50,10-50,80-50,100c0,8.271-6.729,15-15,15h-9.98c-6.539-2.535-13.43-4.628-20.601-6.236
			c2.663-6.915,4.7-15.567,5.353-26.322c6.1-3.426,10.229-9.947,10.229-17.441c0-11.045-8.955-20-20-20c-11.046,0-20,8.955-20,20
			c0,7.292,3.915,13.654,9.746,17.148c-0.758,10.686-3.157,18.237-5.798,23.46c-4.577-0.397-9.229-0.608-13.948-0.608
			s-9.371,0.211-13.948,0.608c-2.641-5.223-5.04-12.774-5.798-23.46c5.831-3.495,9.746-9.856,9.746-17.148c0-11.045-8.954-20-20-20
			c-11.045,0-20,8.955-20,20c0,7.494,4.129,14.016,10.229,17.441c0.652,10.755,2.689,19.408,5.353,26.322
			c-7.171,1.608-14.062,3.702-20.601,6.236H115c-8.271,0-15-6.729-15-15c0-20,0-90-50-100v40c0,0-20-20-20-50c0,0-30,10-30,49.999
			c0,35.286,23.349,70.564,63.174,78.392c3.583,10.068,10.02,18.788,18.334,25.191c-8.68,9.335-15.063,19.96-18.529,31.417H45
			c-13.785,0-25-11.215-25-25v-25H0v25c0,24.813,20.187,45,45,45h15c0,6.874,1.032,13.567,2.979,20H45c-24.813,0-45,20.187-45,45v25
			h20v-25c0-13.785,11.215-25,25-25h27.483c2.763,4.169,5.938,8.156,9.497,11.928C63.496,285.026,50,302.213,50,322.5v15h20v-15
			c0-13.785,11.215-25,25-25h9.582c20.608,12.505,46.844,20,75.418,20c28.573,0,54.809-7.495,75.418-20H265
			c13.785,0,25,11.215,25,25v15h20v-15c0-20.287-13.496-37.475-31.98-43.072c3.559-3.771,6.734-7.759,9.497-11.928H315
			c13.785,0,25,11.215,25,25v25h20v-25c0-24.813-20.186-45-45-45h-17.978c1.946-6.433,2.978-13.126,2.978-20h15
			c24.814,0,45-20.187,45-45v-25h-20V182.5z"
      />
    </svg>
  );
};

export default Crab;
