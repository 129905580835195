import React from 'react';
import { getRandomInt } from '../../util/rng';
import './ShrimpIcon.scss';

const ShrimpIcon = ({ text, size = 250, color = '#666' }) => {
  // Determine if the shrimp should perform a special animation
  const isValue69 = () => getRandomInt(40, 80) === 69;
  const willSwimAround = () => getRandomInt(1, 10) === 5;
  const willWiggle = () => getRandomInt(1, 10) === 7;

  // Determine the appropriate CSS class based on the animation conditions
  const shrimpClass = () => {
    if (willSwimAround()) {
      return 'swimmer';
    }
    if (willWiggle()) {
      return 'wiggle';
    }
    if (isValue69()) {
      return 'not-dancing';
    }
    return '';
  };

  return (
    <div className={`shrimp-icon ${shrimpClass()}`}>
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        className="shrimp-icon__vector"
        viewBox="0 0 500 500"
        stroke={color}
        width={size}
        height={size}
      >
        {text && (
          <text x="0" y="69" className="shrimp-icon__text">
            {text}
          </text>
        )}
        <path
          className="shrimp-icon__eye"
          fill={color}
          stroke="none"
          d={
            'M303.5,143c12.958,0,23.5-10.542,23.5-23.5S316.458,96,303.5,96S280,106.542,280,119.5S290.542,143,303.5,143z M303.5,111 c4.687,0,8.5,3.813,8.5,8.5s-3.813,8.5-8.5,8.5s-8.5-3.813-8.5-8.5S298.813,111,303.5,111z'
          }
        />
        <g className="shrimp-icon__body shrimp-icon__body--tracer">
          <path
            id="antenna"
            className="shrimp-icon__antenna"
            d="M110.1.52c-3.35,5.81-.68,13.09,4.16,17.19,6.29,5.33,15.29,5.46,23.11,5.7,20.11.59,40.25.51,60.37.19,40.03-.64,80.05-2.1,120.09-1.22,20.19.44,40.36,1.33,60.52,2.4,10.08.54,20.17,1.11,30.24,1.7,8.98.52,18.56.3,27.23,3s14.43,9.58,19.62,16.67c4.93,6.72,9.12,13.98,12.55,21.56,6.92,15.27,10.79,31.89,11.88,48.59.58,8.81.8,18.03-1.32,26.67-2.01,8.24-6.65,15.14-13.1,20.59-13.35,11.28-30.91,14.83-46.69,21.34-3.96,1.64-11.67,8.19-10.46,7.48,15.36-8.88,33.32-11.76,48.77-20.47,6.6-3.72,12.73-8.46,17.25-14.59,5.14-6.98,7.23-15.37,7.83-23.89,1.17-16.68-1.15-33.98-5.96-49.95s-12.32-30.7-22.78-43.46c-2.62-3.18-5.39-6.28-8.77-8.67s-7.23-3.84-11.2-4.75c-8.78-1.99-18.1-1.9-27.06-2.42-20.16-1.17-40.32-2.29-60.5-3.09-40.44-1.6-80.81-1.07-121.26-.18-20.25.44-40.5.85-60.75.77-9.95-.04-19.91-.14-29.85-.54-8.06-.33-17.67-1.51-21.83-9.45-1.66-3.17-2.04-6.88-.2-10.05.7-1.22-1.18-2.31-1.88-1.09h0Z"
          />
          <path
            id="tail"
            className="shrimp-icon__tail"
            d="M328.61,404.36c-13.38,4.17-24.58,14.38-29.82,27.38-3.01,7.46-3.99,15.87-2.14,23.75,1.56,6.64,5.26,12.88,10.54,17.25,3.17,2.63,6.83,4.49,10.8,5.59,4.51,1.25,9.23,1.61,13.88,1.96l-.29-2.14c-15.94,3.14-32.24,3.92-48.45,3.31-16.35-.61-32.61-2.58-48.79-4.91-4.07-.59-8.14-1.19-12.2-1.81-1.85-.28-3.8-.43-5.62-.89-.78-.2-1.34-.53-1.62-1.3-.3-.83-.31-1.77-.39-2.63-.38-4.05-.56-8.13-.43-12.19.05-1.8.39-3.29,2.04-4.26s3.5-1.59,5.24-2.32c15.45-6.52,30.9-13.04,46.34-19.56,15.44-6.52,30.9-13.04,46.34-19.56,1.89-.8,3.78-1.59,5.67-2.39.54-.23.65-1.02.39-1.48-.32-.56-.95-.62-1.49-.39-15.95,6.73-31.9,13.46-47.84,20.19-15.95,6.73-31.9,13.46-47.84,20.19-1.94.82-3.89,1.64-5.83,2.46-1.56.66-3.12,1.38-4.12,2.82s-1.06,3.39-1.08,5.13c-.03,2.08,0,4.15.09,6.22s.25,4.26.48,6.38c.17,1.6.51,3.24,1.96,4.17s3.67.98,5.4,1.24c2.09.32,4.17.63,6.27.95,16.6,2.48,33.26,4.76,50.03,5.77,16.62,1,33.36.7,49.83-1.93,2.08-.33,4.15-.7,6.21-1.11,1.27-.25.86-2.05-.29-2.14-7.49-.56-15.21-1.29-21.55-5.7-5.25-3.65-9.09-9.24-10.98-15.32-4.33-13.92,1.27-29.3,11.29-39.34,5.17-5.18,11.56-9.11,18.57-11.3,1.33-.41.76-2.51-.58-2.09h0v-.02Z"
          />
          <path
            id="tailpart1"
            className="shrimp-icon__segment0"
            d="M213.4,475.04c-1.12,4.84-6.18,7.33-10.78,7.6-3.13.19-6.23-.36-9.27-1s-6.29-1.37-9.43-2.06c-12.43-2.76-24.82-5.64-37.19-8.65-10.5-2.56-21.19-5.07-30.93-9.86-4.78-2.35-9.27-5.23-13.19-8.85-4.05-3.73-7.54-8.08-11.83-11.55s-9.7-5.7-14.59-8.56c-4.25-2.48-8.49-6-9.93-10.91-.35-1.18-.48-2.37-.43-3.6l-.8,1.05c12.62-3.27,24.81-8.21,36.14-14.65,3.22-1.83,6.37-3.79,9.45-5.86l-1.32-.17c9.84,12.36,21.68,23.08,34.85,31.81,13.17,8.72,27.69,15.44,42.87,19.79,8.51,2.44,17.24,4.11,26.05,5.02,1.39.14,1.38-2.03,0-2.17-15.52-1.59-30.8-5.72-45.06-12.04-14.26-6.32-27.44-14.71-39.09-24.95-6.57-5.78-12.64-12.14-18.09-18.99-.28-.35-.95-.41-1.32-.17-10.71,7.2-22.35,13-34.57,17.15-3.46,1.17-6.96,2.21-10.5,3.13-.48.12-.78.57-.8,1.05-.22,5.25,2.9,9.92,6.79,13.18,4.45,3.73,9.96,5.78,14.89,8.74,5,3,8.83,7.31,12.86,11.43s8.22,7.44,13.09,10.2c9.69,5.48,20.59,8.36,31.32,11.01,12.85,3.16,25.72,6.17,38.64,9.06,3.27.73,6.54,1.45,9.8,2.16s6.28,1.37,9.49,1.47c5.13.17,10.61-1.51,13.58-5.97.65-.98,1.12-2.1,1.39-3.24.32-1.36-1.78-1.94-2.09-.58h0v-.02Z"
          />
          <path
            id="back-lower"
            className="shrimp-icon__segment1"
            d="M112.9,394.63c-4.04-12.12-7.04-24.59-8.93-37.23-1.89-12.65-2.66-25.48-2.32-38.26.09-3.2.27-6.39.46-9.58.14-2.4.08-5.07-1.63-6.94-1.9-2.07-5.23-2.16-7.81-2.63-3.2-.57-6.4-1.19-9.59-1.83-12.59-2.56-25.05-5.67-37.36-9.33-12.31-3.66-24.45-7.87-36.38-12.61-1.49-.59-2.96-1.19-4.44-1.8-.4-.16-1.05.1-1.22.5-8.76,20.02-.06,42.32,7.09,61.42,4.01,10.73,8.2,21.43,12.76,31.94,4.18,9.63,9.12,18.81,15.75,26.98,6.93,8.55,15.35,15.87,24.75,21.58,1.2.73,2.29-1.15,1.09-1.87-8.15-4.95-15.51-11.13-21.82-18.29-6.7-7.61-11.88-16.26-16.1-25.45-4.68-10.18-8.74-20.69-12.72-31.16-3.81-10.01-7.74-20.09-10.19-30.53-2.59-11.02-3.38-22.92,1.26-33.52l-1.22.5c11.72,4.81,23.65,9.1,35.74,12.86,12.09,3.76,24.36,6.99,36.74,9.68,6.17,1.34,12.36,2.48,18.57,3.61,1.25.22,2.68.48,3.57,1.48.78.89.99,2.13,1.04,3.27.12,2.87-.28,5.79-.41,8.66-.51,12.59.07,25.24,1.71,37.74s4.32,24.83,8.05,36.86c.47,1.52.96,3.03,1.46,4.54.44,1.32,2.54.75,2.09-.58h0Z"
          />
          <path
            id="back"
            className="shrimp-icon__segment2"
            d="M101.93,303.73c8.27-10.16,16.54-20.32,24.8-30.48l6.14-7.55c1.82-2.24,3.89-4.45,4.89-7.2,1.74-4.79-.49-10.5-3.2-14.51-5.38-7.95-14.15-12.62-21.32-18.7-7.17-6.08-13.66-14.08-19.31-22.07s-10.93-16.41-16.77-24.36c-5.78-7.87-12.17-15.34-19.93-21.32-2.09-1.61-4.46-3.53-7.28-3.24-2.59.27-4.92,2.05-6.99,3.5-4.07,2.86-7.75,6.24-10.99,10-6.59,7.66-11.37,16.71-14.81,26.17-3.86,10.64-5.87,21.8-7.19,33.01-1.49,12.67-2.09,25.43-3.53,38.1-.35,3.1-.75,6.2-1.23,9.27-.21,1.37,1.88,1.95,2.09.58,1.89-12.25,2.64-24.62,3.72-36.96,1.04-11.72,2.38-23.5,5.51-34.87,2.61-9.5,6.41-18.74,11.85-26.98,2.67-4.03,5.74-7.8,9.24-11.15,1.78-1.7,3.66-3.3,5.64-4.75.96-.7,1.96-1.38,2.96-2.01,1.13-.72,2.35-1.54,3.7-1.74,2.55-.38,4.98,1.95,6.82,3.42s3.63,3.04,5.34,4.68c3.42,3.28,6.56,6.84,9.51,10.55,5.99,7.52,11.17,15.63,16.57,23.58,5.39,7.94,11.01,15.81,17.74,22.7,3.3,3.39,6.89,6.43,10.67,9.27s7.56,5.49,11,8.66c3.24,2.99,6.17,6.49,7.64,10.71.88,2.55,1.41,5.43.38,8.02-1.07,2.72-3.38,4.99-5.19,7.22-8.04,9.88-16.08,19.76-24.12,29.64l-5.92,7.27c-.88,1.08.65,2.62,1.54,1.53h.01Z"
          />
          <path
            id="neck"
            className="shrimp-icon__segment3"
            d="M137.6,256.06c10.37-6.64,21.8-11.52,33.78-14.4,2.75-.66,5.8-.9,8.38-2.11s3.61-3.66,3.7-6.36c.09-3.18-.36-6.43-.54-9.61-.2-3.42-.36-6.83-.49-10.25-.54-13.67-.59-27.36-.32-41.04s.86-27.38,1.63-41.06c.77-13.75,1.72-27.48,2.67-41.2.12-1.68.24-3.36.36-5.03.04-.64-.54-1.02-1.09-1.09-10.79-1.3-21.72-1.35-32.52-.2s-21.49,3.45-31.82,6.85c-10.27,3.39-20.2,7.85-29.54,13.31-9.34,5.46-18.16,11.98-26.1,19.37-4.03,3.75-8.04,7.67-11.17,12.21s-5.49,9.87-4.54,15.37c.22,1.29.67,2.52,1.25,3.69.62,1.25,2.49.16,1.88-1.09-2.41-4.92-.65-10.4,1.99-14.84,2.79-4.68,6.68-8.59,10.59-12.34,7.63-7.33,16.09-13.77,25.13-19.26,9.01-5.46,18.59-9.98,28.53-13.46s20.34-5.97,30.83-7.3c10.49-1.32,21.03-1.53,31.52-.57,1.33.12,2.64.27,3.96.42l-1.09-1.09c-.95,13.48-1.9,26.98-2.7,40.48s-1.46,27-1.82,40.51c-.36,13.56-.43,27.13-.05,40.69.19,6.72.49,13.43.91,20.14.1,1.6.24,3.2.32,4.8.07,1.28.1,2.66-.38,3.87-1.03,2.61-4.39,2.84-6.76,3.34-11.85,2.45-23.31,6.88-33.76,12.99-1.3.76-2.59,1.55-3.85,2.37-1.17.75-.09,2.63,1.09,1.87h0Z"
          />
          <path
            id="head"
            className="shrimp-icon__head"
            d="M187.74,87.19c19.57-4.08,39.58-5.43,59.55-5.2s39.93,1.97,59.8,3.97c20.01,2.02,40,4.31,60.08,5.67,9.96.67,19.93,1.11,29.9,1.17,5.06.03,10.11-.04,15.17-.24,4.3-.17,8.68-.72,12.98-.35,4.06.34,7.77,1.98,9.16,6.08,1.59,4.68,1.62,10.13,1.54,15.03-.17,10.28-2.26,20.41-4.34,30.44-1.91,9.2-4.02,18.49-7.91,27.09s-9.23,15.43-15.96,21.53c-6.72,6.09-14.1,10.92-21.8,15.4-8.05,4.68-16.45,8.78-25.09,12.25-17.17,6.9-35.31,11.41-53.71,13.33-9.14.95-18.35,1.27-27.53.93-9.88-.36-19.64-1.67-29.44-2.91-9.8-1.24-20.17-2.41-30.31-1.9-4.91.25-9.8.88-14.57,2.05-3.78.93-7.54,2.12-11.45,2.37-3.5.22-6.94-.38-9.23-3.26-.87-1.09-2.4.45-1.54,1.53,4.93,6.2,14.15,3.7,20.63,2,9.98-2.62,20.16-3.02,30.43-2.31,10.43.72,20.75,2.42,31.14,3.54,9.48,1.02,19.03,1.4,28.57,1.06,18.83-.68,37.58-4.04,55.48-9.92,17.75-5.83,35.03-14.06,50.27-24.9,7.28-5.18,14.05-11.14,19.38-18.34,5.63-7.61,9.13-16.48,11.61-25.55,2.62-9.59,4.61-19.49,6.12-29.32.8-5.16,1.37-10.37,1.43-15.59.05-4.87-.06-10.24-1.56-14.92-2.85-8.9-12.72-8.09-20.27-7.69-10.18.52-20.38.53-30.57.2-20.64-.67-41.21-2.74-61.74-4.88-20.54-2.14-40.83-4.31-61.34-5.26-20.29-.93-40.67-.6-60.81,2.27-4.91.7-9.79,1.56-14.64,2.57-1.37.28-.79,2.38.58,2.09h-.01Z"
          />
          <path
            id="leg3"
            className="shrimp-icon__leg3"
            d="M209.92,232.86c10.63,11.32,20.47,23.37,29.4,36.08,2.13,3.03,4.2,6.11,5.89,9.41s2.9,7.03,3.69,10.76c1.62,7.71,1.9,15.69,2.52,23.52.75,9.29,1.49,18.59,2.24,27.88.11,1.38,2.28,1.4,2.17,0-.67-8.4-1.35-16.79-2.02-25.19-.64-8-.98-16.11-2.3-24.03-.63-3.81-1.58-7.56-3.07-11.13-1.47-3.5-3.43-6.76-5.56-9.91-4.38-6.46-9.1-12.72-13.98-18.81-5.54-6.94-11.37-13.65-17.44-20.12-.96-1.02-2.49.51-1.54,1.53h0Z"
          />
          <path
            id="leg2"
            className="shrimp-icon__leg2"
            d="M256.94,234.02c3.81,6.87,7.46,13.86,11.5,20.6,1.84,3.08,3.9,6.02,6.35,8.64,2.51,2.68,5.3,5.09,7.73,7.83,9.28,10.47,12.89,24.69,13.77,38.34.57,8.84.12,17.7-.33,26.53-.07,1.4,2.1,1.39,2.17,0,.78-15.19,1.53-30.78-2.88-45.53-1.93-6.46-4.83-12.72-8.93-18.1-2.17-2.83-4.67-5.31-7.22-7.8-2.7-2.63-5.16-5.37-7.23-8.52-2.34-3.57-4.33-7.36-6.4-11.09-2.22-4-4.44-8.01-6.66-12.01-.68-1.22-2.56-.13-1.88,1.09h0Z"
          />
          <path
            id="leg1"
            className="shrimp-icon__leg1"
            d="M303.25,235.72c9.88,11.63,19.32,23.63,28.31,35.96,2.11,2.9,4.3,5.79,5.84,9.04s2.21,6.83,2.67,10.36c1,7.67,1.53,15.4,1.73,23.13.23,8.79-.02,17.59-.75,26.35-.12,1.39,2.06,1.38,2.17,0,1.3-15.82,1.07-31.75-.75-47.5-.42-3.63-.9-7.29-2.11-10.76s-3.09-6.36-5.16-9.25c-4.5-6.29-9.17-12.46-13.92-18.56-5.36-6.88-10.87-13.64-16.51-20.3-.91-1.06-2.43.48-1.54,1.53h.01Z"
          />
        </g>
      </svg>
    </div>
  );
};

export default ShrimpIcon;
