import React from 'react';

const ShrimpSvg = ({ size, fill, className }) => (
  <svg 
    version="1.1" 
    xmlns="http://www.w3.org/2000/svg" 
    x="0" 
    y="0" 
    viewBox="0 0 1000 1000"
    fill={fill || 'black'}
    className={`shrimp ${className || ''}`}
    width={size || 250}
    height={size || 250}>
    <path d="M267.9,203.7c9.2-16.7,30.3-22.7,47-13.5c16.8,9.2,22.8,30.2,13.5,46.8c-9.3,16.6-30.3,22.7-47,13.5C264.7,241.3,258.6,220.4,267.9,203.7L267.9,203.7L267.9,203.7z M203.7,300.9c51.2,36.4,217.3,31.5,285.2,27.4c-29.6-62.5-45.9-130.5-40.6-207.2c-83-8.4-230.6,30.3-254.7,109.2c-52.4-79.5-67.1-147.7-60.9-209.6c-5.8,1.8-11.5,3.6-17.2,5.3c-2.7,75,11.1,146.2,46.8,212.5C83.7,187.9,43.1,132.4,23.4,74.2c-4.5,4-9,7.9-13.4,11.9C47.5,177.9,108.6,252.1,203.7,300.9L203.7,300.9L203.7,300.9z M454.4,343.5c-20,34.4-40,68.8-59.9,103.1c6.4,33.7,12.9,67.4,19.3,101.1c7.1-1.4,14.1-2.7,21.2-4.2c-2.9-29-5.6-58.1-8.5-87.2c25.5-32.3,51-64.6,76.4-97C486.8,354,470.6,348.7,454.4,343.5L454.4,343.5L454.4,343.5z M601.3,408.5l42.4,29.6c-31.5,21.9-76.5,54.3-108,76.2c-9.4,23.1-18.9,46.1-28.4,69.2c-10.3,0.4-20.6,0.8-30.9,1.1c10.7-28.6,21.4-57.2,32.1-85.8C539.4,468.7,570.3,438.6,601.3,408.5L601.3,408.5L601.3,408.5z M539.7,374c14.5,8.8,29,17.7,43.5,26.5c-32.3,25.7-64.6,51.2-96.8,76.9c-4,28.9-8,57.9-11.9,86.8c-7.2-0.3-14.4-0.6-21.5-0.8c1.5-34.3,3-68.5,4.6-102.8C484.8,431.8,512.2,402.9,539.7,374L539.7,374L539.7,374z M750.1,667.3c47.8-21.5,92.4-7.9,135.7,19.5c78.7,91.6,105.9,190.2,103.9,292.5c-69.9-48.3-139.8-96.7-209.7-145c0,0-234.7,122.1-226.3,111.2C595.1,839,656,743.7,750.1,667.3L750.1,667.3L750.1,667.3z M756.2,639.6c52.7-23.8,100.4-17.9,144.4,10.4c51.3-74.5,88.2-155,89.4-250.3c-94.6,14.9-183.6,41.6-250.5,115.7C745.1,556.8,750.7,598.2,756.2,639.6L756.2,639.6L756.2,639.6z M643.3,382.7c25.9,31.9,52,63.7,77.9,95.5c64.6-73.9,137.1-111.9,211.8-139.9c-21.9-61-57.8-116.2-115.1-162.6C737.6,227.9,670.8,290.4,643.3,382.7L643.3,382.7L643.3,382.7z M515.1,325.5c-24-76.3-18.2-155.3,6.6-236.1c65.4-1,130,5.8,191.2,46.6c-45,71.6-80.4,147-96.1,230.4C582.8,352.8,548.9,339.1,515.1,325.5L515.1,325.5z" />
  </svg>
);

export default ShrimpSvg;
