const ComputerKeyToNote = {
  a: 'C',
  w: 'Cs',
  s: 'D',
  e: 'Ds',
  d: 'E',
  f: 'F',
  t: 'Fs',
  g: 'G',
  y: 'Gs',
  h: 'A',
  u: 'As',
  j: 'B',
};

export default ComputerKeyToNote;
